/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  ArrowBackOutlined,
  GroupAdd,
  Business,
  Edit,
  Delete,
  MoreVert,
  SyncAlt,
} from '@material-ui/icons';

// components
import Modal from '../components/modal';
import Tooltip from '../components/tooltip';
import DeleteTeam from './components/deleteTeam';
import DeleteTeamUser from './components/deleteTeamUser';
import NoResults from '../portal/noResults';
import CardLoader from '../components/cardLoader';
import ActionCard from '../storyboard/components/ActionCard';
import TabPanel from '../subscription/components/tabPanel';
import CustomMenu from '../components/menu';
import { manageTeamsSerivceCall } from './duck/operations';
import { formatDateTime } from '../helperFunctions';

// actions
import { updateUserTeam } from '../main-wrapper/duck/actions';

// constants
import { MODAL_TYPES } from '../constants';
import sidebarTypes from '../components/Sidebar/constants';

// Styles
import {
  Flex,
  Text,
  TableViewContainerStyles,
  IconBreadCrumbStyles,
  ContainerFluid,
  CustomAvatarStyles,
  CustomAvatarGroupStyles,
  GridListParentStyles,
} from '../styles/common';
import { Fab } from '../components/Button';
import { colors, font, fs, devices } from '../styles/variables';
import { whiteAlpha } from '../styles/utils';

// Utils
import Mixpanel from '../../utils/mixpanel';

const CardGridStyles = styled.div`
  padding: 0 2rem;
  display: flex;
  gap: 1.5rem;

  .action-card {
    flex: 1;
  }

  @media screen and (max-width: ${devices.lg}) {
    flex-wrap: wrap;
    .action-card {
      flex-basis: 49%;
    }
  }
`;

const TabContainerStyles = styled(TableViewContainerStyles)`
  .table-head {
    &.first {
      padding-left: 5rem;
    }
  }

  .members-table {
    .table-head,
    .table-item {
      width: calc(100% / 3);
    }
  }

  .table-head,
  .table-item {
    width: calc(60% / 4);
    .anchor-btn {
      background-color: transparent;
      border: none;
      display: inline-block;
      cursor: auto;

      &.admin {
        cursor: pointer;
        &:hover,
        &:focus {
          > p {
            color: ${colors.blue.shadeOne};
          }
        }
      }
    }

    &.first {
      width: 30%;
    }

    &.last {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &.menu-options {
      .MuiIconButton-root {
        padding: 0.5rem;
        background-color: ${whiteAlpha(0.2)};
        &:hover {
          background-color: ${colors.blue.primary};
        }

        .MuiSvgIcon-root {
          fill: ${colors.white};
        }
      }
    }
  }
`;

const tabStyles = makeStyles(() => ({
  root: {
    '& .MuiTabs-root': {
      borderBottom: '1px solid rgba(44, 134, 255, 0.3)',
    },

    '& .MuiTab-root': {
      fontSize: fs.sm,
      textTransform: 'inherit',
      color: whiteAlpha(0.7),
      padding: '0 2rem',
      fontWeight: font.medium,
      minWidth: 'auto !important',

      '&.Mui-selected': {
        color: colors.white,
      },
    },

    '& .MuiTabs-indicator': {
      backgroundColor: colors.blue.primary,
    },
  },
}));

const TABS = ['teams', 'users'];
const CARD_CLICK_TYPES = {
  createTeam: 'CREATE_TEAM',
  billingDetails: 'BILLING_DETAILS',
  integration: 'INTEGRATION',
};

const ACTION_ICON_CLICK = {
  editTeam: 'EDIT_TEAM',
  deleteTeam: 'DELETE_TEAM',
  deleteTeamUser: 'DELETE_TEAM_USER',
};

const MODAL_INITIAL_STATE = {
  selected: null,
  view: 'primary',
  title: null,
  type: null,
  toggle: false,
};

export const toggleTeamMembersSidebar = ({ toggleSidebar, componentProps }) => {
  toggleSidebar({
    type: sidebarTypes.settingsTeamMembers,
    show: true,
    title: 'Team Users',
    componentProps: { ...componentProps },
  });
};

const SettingsComponent = props => {
  const dispatch = useDispatch();
  const tabsClasses = tabStyles();
  const {
    pusher,
    userDetails,
    history,
    toggleSidebar,
    toggleToast,
    teams,
    users,
    loadTabs,
    getTeamSettings,
    loadBillingDetails,
    loadSettingsPermissions,
    permissions,
    toggleUpgradeModal,
    checkTeamCreatePermissions,
  } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [modal, toggleModal] = useState(MODAL_INITIAL_STATE);
  const [loading, toggleLoading] = useState({
    tabs: true,
    overview: true,
  });

  const onTabPanelChange = useCallback(
    (e, newValue) => {
      let updatedData = null;
      toggleLoading(prev => ({
        ...prev,
        tabs: true,
      }));
      setSelectedTabIndex(newValue);
      manageTeamsSerivceCall({
        [TABS[newValue]]: true,
      })
        .then(res => {
          const { data } = res?.data;
          if (newValue) {
            updatedData = {
              users: data?.users?.team_users,
            };
            loadTabs({
              data: updatedData,
              key: 'users',
            });
          } else {
            updatedData = {
              teams: data?.teams,
            };
            loadTabs({
              data: updatedData,
              key: 'teams',
            });
          }
          toggleLoading(prev => ({
            ...prev,
            tabs: false,
          }));
        })
        .catch(() => {
          toggleLoading(prev => ({
            ...prev,
            tabs: false,
          }));
        });
    },
    [selectedTabIndex]
  );

  const fetchTeams = () => {
    getTeamSettings()
      .then(res => {
        const { data, response, errormessage } = res?.data;
        if (response) {
          loadBillingDetails(data?.billing);
          loadSettingsPermissions(data?.permissions);
          loadTabs({ data: { teams: data?.teams }, key: 'teams' });
          toggleLoading(prev => ({
            ...prev,
            tabs: false,
          }));
        } else {
          throw new Error(errormessage);
        }
      })
      .catch(() => {
        toggleLoading(prev => ({
          ...prev,
          tabs: false,
        }));
      });
  };

  useEffect(() => {
    let channel = null;
    if (pusher && userDetails) {
      channel = pusher.subscribe(`private-${userDetails?.user_id}`);
      if (channel) {
        channel.bind('team_update', data => {
          dispatch(updateUserTeam({ data, key: 'team_update' }));
          loadTabs({
            data,
            key: 'teams',
            type: 'team_update',
          });
        });
        channel.bind('team_delete', data => {
          dispatch(updateUserTeam({ data, key: 'team_delete' }));
          loadTabs({ data, key: 'teams', type: 'team_delete' });
        });
        channel.bind('team_add', data => {
          dispatch(updateUserTeam({ data }));
          loadTabs({ data, key: 'teams', type: 'team_add' });
        });
        channel.bind('billing_detail', data => {
          loadBillingDetails(data?.billing);
        });
      }
    }

    return () => {
      if (channel && pusher) {
        channel.unbind();
        pusher.unsubscribe();
      }
    };
  }, [pusher, userDetails]);

  useEffect(() => {
    fetchTeams();
  }, []);

  const onCardActionClick = type => {
    if (type === CARD_CLICK_TYPES.createTeam) {
      try {
        (async () => {
          const res = await checkTeamCreatePermissions({
            action: 'create_team',
          });
          const {
            data: { data, response, errormessage },
          } = res;
          if (response) {
            toggleSidebar({
              type: sidebarTypes.settingsCreateTeam,
              show: true,
              title: 'Create Team',
            });
          } else if (data.upgrade_required) {
            toggleUpgradeModal({ show: true, data });
          } else {
            throw new Error(errormessage);
          }
        })();
      } catch (err) {
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: err?.message,
            variant: 'error',
          },
        });
      }
    } else if (type === CARD_CLICK_TYPES.billingDetails) {
      toggleSidebar({
        type: sidebarTypes.settingsBillingDetails,
        show: true,
        title: 'Billing Details',
      });
    } else if (type === CARD_CLICK_TYPES.integration) {
      toggleSidebar({
        type: sidebarTypes.integration,
        show: true,
        title: 'Applications',
      });
    }
  };

  const renderModals = () => {
    let component = null;
    const commonProps = {
      toggleToast,
      toggleModal,
      modal: { ...modal, intial: MODAL_INITIAL_STATE },
    };

    switch (modal?.type) {
      case MODAL_TYPES.deleteTeam:
        component = <DeleteTeam {...commonProps} />;
        break;

      case MODAL_TYPES.deleteTeamUser:
        component = <DeleteTeamUser {...commonProps} />;
        break;

      default: {
        component = null;
      }
    }
    return component;
  };

  const actionIconClick = useCallback(({ type, data }) => {
    if (type === ACTION_ICON_CLICK.deleteTeam) {
      toggleModal(prev => ({
        ...prev,
        selected: data,
        title: 'Delete Team',
        toggle: true,
        type: MODAL_TYPES.deleteTeam,
      }));
    }
    if (type === ACTION_ICON_CLICK.deleteTeamUser) {
      toggleModal(prev => ({
        ...prev,
        selected: data,
        title: 'Delete User',
        toggle: true,
        type: MODAL_TYPES.deleteTeamUser,
      }));
    } else if (type === ACTION_ICON_CLICK.editTeam) {
      toggleSidebar({
        type: sidebarTypes.settingsCreateTeam,
        show: true,
        title: 'Manage Team',
        componentProps: {
          ...data,
          team_id: data?.team_db_id,
        },
      });
    }
  }, []);

  const getUsersAvatars = useCallback(data => {
    const filteredUsers = [
      ...data?.detail?.owners,
      ...(data?.detail?.members || []),
    ];
    return (
      <CustomAvatarGroupStyles
        style={{ cursor: 'pointer' }}
        max={4}
        onClick={() =>
          toggleTeamMembersSidebar({
            toggleSidebar,
            componentProps: { team_id: data?.detail?.team_db_id },
          })
        }
        length={filteredUsers.length}>
        {filteredUsers.map(item => (
          <Tooltip
            title={item?.name}
            hide
            placement='top'
            arrow
            key={item?.user_db_id}>
            <CustomAvatarStyles color={item?.user_badge_color} border>
              {item?.name.charAt(0)}
            </CustomAvatarStyles>
          </Tooltip>
        ))}
      </CustomAvatarGroupStyles>
    );
  }, []);

  const getTeamsInUsersTab = useCallback(names => {
    return (
      <CustomAvatarGroupStyles
        style={{ cursor: 'pointer' }}
        max={4}
        length={names.length}
        onClick={() => {
          toggleSidebar({
            type: sidebarTypes.settingsTeam,
            show: true,
            title: 'Team',
            componentProps: {
              teams: names,
            },
          });
        }}>
        {names.map(item => (
          <Tooltip title={item} hide placement='top' arrow key={item}>
            <CustomAvatarStyles color={item?.user_badge_color}>
              {item.charAt(0)}
            </CustomAvatarStyles>
          </Tooltip>
        ))}
      </CustomAvatarGroupStyles>
    );
  }, []);

  const onMenuItemChange = (item, data) => {
    switch (item?.field) {
      case ACTION_ICON_CLICK.editTeam:
        actionIconClick({
          type: ACTION_ICON_CLICK.editTeam,
          data: {
            ...data,
          },
        });
        break;
      case ACTION_ICON_CLICK.deleteTeam:
        actionIconClick({
          type: ACTION_ICON_CLICK.deleteTeam,
          data: {
            ...data,
          },
        });
        break;
      default:
        return false;
    }
    return null;
  };

  return (
    <div>
      <ContainerFluid style={{ paddingBottom: '3.5rem' }}>
        <IconBreadCrumbStyles>
          <Fab
            type='submit'
            size='small'
            color='primary'
            aria-label='back'
            onClick={() => {
              Mixpanel.track('Settings Settings');
              history.goBack();
            }}
            icon={<ArrowBackOutlined />}
          />
          <Text className='page-name'>Team Settings</Text>
        </IconBreadCrumbStyles>
      </ContainerFluid>

      <div style={{ paddingBottom: '3.5rem' }}>
        <CardGridStyles>
          <ActionCard
            className='action-card'
            icon={<GroupAdd />}
            head='Create Team'
            para='Create & Manage Teams. Add & Manage Members in the Team'
            onClick={() => onCardActionClick(CARD_CLICK_TYPES.createTeam)}
          />
          <ActionCard
            className='action-card'
            icon={<Business />}
            head='Billing Details'
            para='Add & Manage Billing information for Invoice generation.'
            onClick={() => onCardActionClick(CARD_CLICK_TYPES.billingDetails)}
          />
          {permissions?.can_view_app_integration && (
            <ActionCard
              className='action-card'
              icon={<SyncAlt />}
              head='App Integration'
              para='Integrate third-party apps to your team'
              onClick={() => onCardActionClick(CARD_CLICK_TYPES.integration)}
            />
          )}
        </CardGridStyles>
      </div>

      <GridListParentStyles>
        <TabContainerStyles className={tabsClasses.root}>
          <Tabs
            value={selectedTabIndex}
            onChange={onTabPanelChange}
            aria-label='Teams / Members'>
            <Tab label='Teams' />
            {permissions?.can_view_app_integration && <Tabs label='Members' />}
          </Tabs>

          {loading?.tabs ? (
            <CardLoader />
          ) : (
            <>
              <TabPanel value={selectedTabIndex} index={0}>
                {teams?.data.length > 0 ? (
                  <>
                    <div>
                      <Flex
                        className='items-center justify-between'
                        style={{ marginTop: '1rem' }}>
                        <Text className='table-head first'>Name</Text>
                        <Text className='table-head'>Team ID</Text>
                        <Text className='table-head'>Region</Text>
                        <Text className='table-head'>Members</Text>
                        <Text className='table-head'>Created On</Text>
                        <Text className='table-head last' />
                      </Flex>

                      {teams?.data.map(
                        (
                          {
                            is_admin,
                            name,
                            team_db_id,
                            no_of_users,
                            created_on,
                            detail,
                            aws_config,
                            team_id,
                            two_factor_authentication_enabled,
                          },
                          index
                        ) => (
                          <Flex
                            className='items-center justify-between table-row'
                            key={team_db_id}>
                            <div className='table-item flex items-center first'>
                              <span style={{ color: whiteAlpha(0.8) }}>{`${
                                index + 1 <= 9 ? '0' : ''
                              }${index + 1}`}</span>
                              <button
                                type='button'
                                className={
                                  is_admin ? 'anchor-btn admin' : 'anchor-btn'
                                }
                                onClick={e => {
                                  if (is_admin) {
                                    actionIconClick({
                                      type: ACTION_ICON_CLICK.editTeam,
                                      data: {
                                        name,
                                        team_db_id,
                                        no_of_users,
                                        created_on,
                                        is_admin,
                                        two_factor_authentication_enabled,
                                      },
                                    });
                                  } else {
                                    e.preventDefault();
                                  }
                                }}>
                                <Text>{name}</Text>
                              </button>
                            </div>
                            <Text className='table-item'>{team_id}</Text>
                            <Text className='table-item'>
                              {aws_config?.region}
                            </Text>
                            <div className='table-item'>
                              {getUsersAvatars({ detail, is_admin })}
                            </div>
                            <Text className='table-item'>
                              {formatDateTime(created_on)?.date}
                            </Text>
                            <div className='table-item last menu-options'>
                              {is_admin && (
                                <CustomMenu
                                  icon={<MoreVert />}
                                  onMenuItemClick={item =>
                                    onMenuItemChange(item, {
                                      is_admin,
                                      name,
                                      team_db_id,
                                      no_of_users,
                                      created_on,
                                      detail,
                                      two_factor_authentication_enabled,
                                    })
                                  }
                                  menuList={[
                                    {
                                      icon: <Edit />,
                                      name: 'Manage Team',
                                      field: ACTION_ICON_CLICK.editTeam,
                                    },
                                    {
                                      icon: <Delete />,
                                      name: 'Delete Team',
                                      field: ACTION_ICON_CLICK.deleteTeam,
                                      isVisible: permissions.can_delete_team,
                                    },
                                  ]}
                                />
                              )}
                            </div>
                          </Flex>
                        )
                      )}
                    </div>
                  </>
                ) : (
                  <NoResults />
                )}
              </TabPanel>

              <TabPanel
                value={selectedTabIndex}
                index={1}
                className='members-table'>
                {users?.data.length > 0 ? (
                  <>
                    <div>
                      <Flex
                        className='items-center justify-between'
                        style={{ marginTop: '1rem' }}>
                        <Text className='table-head first'>Name</Text>
                        <Text className='table-head'>Added On</Text>
                        <Text className='table-head'>Team</Text>
                      </Flex>

                      {users?.data.map((item, index) => (
                        <Flex
                          className='items-center justify-between table-row'
                          key={item?.user_db_id}>
                          <div className='table-item flex items-center first'>
                            <span style={{ color: whiteAlpha(0.8) }}>{`${
                              index + 1 < 9 && '0'
                            }${index + 1}`}</span>
                            <span>
                              <Text>{item?.name}</Text>
                            </span>
                          </div>
                          <Text className='table-item'>{item?.created_on}</Text>
                          <div className='table-item'>
                            {getTeamsInUsersTab(item?.teams)}
                          </div>
                        </Flex>
                      ))}
                    </div>
                  </>
                ) : (
                  <NoResults />
                )}
              </TabPanel>
            </>
          )}
        </TabContainerStyles>
      </GridListParentStyles>

      <Modal
        onClose={() =>
          toggleModal({
            title: null,
            type: null,
            toggle: false,
            view: 'primary',
          })
        }
        title={modal?.title}
        open={modal?.toggle}>
        {renderModals()}
      </Modal>
    </div>
  );
};

SettingsComponent.propTypes = {
  history: PropTypes.instanceOf(Object),
  teams: PropTypes.instanceOf(Object),
  users: PropTypes.instanceOf(Object),
  pusher: PropTypes.instanceOf(Object),
  userDetails: PropTypes.instanceOf(Object),
  permissions: PropTypes.instanceOf(Object),
  goBack: PropTypes.func,
  toggleSidebar: PropTypes.func,
  loadTabs: PropTypes.func,
  loadOrganisation: PropTypes.func,
  toggleToast: PropTypes.func,
  selectedTeam: PropTypes.string,
  getTeamSettings: PropTypes.func,
  loadBillingDetails: PropTypes.func,
  loadSettingsPermissions: PropTypes.func,
  toggleUpgradeModal: PropTypes.func,
  checkTeamCreatePermissions: PropTypes.func,
};

export default SettingsComponent;
