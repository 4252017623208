import * as chmln from '@chamaeleonidae/chmln';

export const chameleonInit = () => {
  if (process.env.ENVIRONMENT === 'development') {
    console.log('CHameleon Initiated');
    chmln.init(process.env.CHAMELEON_VERIFICATION_SECRET, {
      fastUrl: 'https://fast.chameleon.io/',
    });
  }
};

export const chameleonIdentifyUser = (userId, userProperties = {}) => {
  if (process.env.ENVIRONMENT === 'development') {
    console.log('CHameleon user Identify');
    chmln.identify(userId, userProperties);
  }
};

export const chameleonTrack = () => {
  chmln.track();
};
