import React from 'react';
import styled from 'styled-components';
import { Checkbox as MaterialCheckbox } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import PropTypes from 'prop-types';

// Styles
import { colors } from '../../styles/variables';

const StyledCheckbox = styled(MaterialCheckbox)`
  &.MuiCheckbox-root {
    padding: 0.65rem 0;
  }
`;

const Icon = styled.span`
  width: 1.9rem;
  height: 1.9rem;
  border: ${`0.1rem solid ${colors.blue.shadeOne}`};
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ checked }) => checked && colors.blue.shadeOne};
  border-color: ${({ checked }) => checked && 'transparent'};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const defaultProps = {
  disabled: false,
};

const Checkbox = props => {
  const { onChange, disabled, ...otherProps } = props;
  return (
    <StyledCheckbox
      disabled={disabled}
      {...otherProps}
      onChange={onChange}
      icon={<Icon disabled={disabled} />}
      checkedIcon={
        <Icon checked disabled={disabled}>
          <Check
            style={{
              width: '1.6rem',
              height: '1.6rem',
              color: colors.white,
            }}
          />
        </Icon>
      }
    />
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = defaultProps;

export default Checkbox;
