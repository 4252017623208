import React from 'react';
import styled, { css } from 'styled-components';
import { PropTypes } from 'prop-types';

import sidebarTypes from '../../../components/Sidebar/constants';

import { Body } from '../../../styles/sidebar';
import { font, colors, fs, radius } from '../../../styles/variables';
// import { Text } from '../../../styles/common';
import { whiteAlpha } from '../../../styles/utils';

import S3Icon from './Amazon-S3.svg';
import SprinklrIcon from './Sprinklr.svg';

export const ListContainerStyles = styled.ul`
  display: grid;
  row-gap: 1.5rem;
  list-style-type: none;
`;

const ListItemStyles = styled.button`
  position: relative;
  padding: 1.4rem;
  width: 100%;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  text-align: left;
  border-radius: 1rem;
  border: 0.1rem solid ${whiteAlpha(0.3)};
  background-color: transparent;
  &:hover {
    border-color: ${colors.white};
  }

  h4 {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.6rem;
    font-weight: ${font.normal};
  }

  &.application-btn {
    height: 84px;
    cursor: pointer;
  }
`;

export const ListItemExtendedStyles = styled(ListItemStyles)`
  position: relative;
  display: grid;
  cursor: pointer;
  row-gap: 1.5rem;

  p,
  h4 {
    font-weight: ${font.medium};
    padding: 0;
  }

  p {
    text-transform: uppercase;
    font-size: 1rem;
    color: ${whiteAlpha(0.6)};
    margin-bottom: 0.5rem;
  }

  h4 {
    color: ${colors.white};
    line-height: 1.4;
    font-size: ${fs.sm};
  }
`;

export const IntegrationStatusStyles = styled.div`
  background-color: ${whiteAlpha(0.15)};
  border-radius: ${radius.default};
  text-align: center;

  p {
    color: #ffd700;
  }

  ${({ status }) =>
    status === 'failed' &&
    css`
      p {
        color: red;
      }
    `}

  ${({ status }) =>
    (status === 'success' || status === 'integrated') &&
    css`
      p {
        color: #25f225;
      }
    `}
`;

const Integration = ({ toggleSidebar }) => {
  const openSidebar = ({ type, title }) => {
    toggleSidebar({
      type,
      show: true,
      title,
    });
  };

  return (
    <>
      <Body noFooter>
        <ListContainerStyles>
          <ListItemStyles
            className='application-btn'
            type='button'
            onClick={() =>
              openSidebar({
                title: 'Amazon S3',
                type: sidebarTypes.s3Integration,
              })
            }>
            <span>
              <img src={S3Icon} alt='S3' />
            </span>
            <div>
              <h4>Amazon S3</h4>
            </div>
          </ListItemStyles>

          <ListItemStyles
            className='application-btn'
            type='button'
            onClick={() =>
              openSidebar({
                title: 'Contido',
                type: sidebarTypes.contidoIntegration,
              })
            }>
            <span>
              <img
                src={`${process.env.BASE_ASSETS_PATH}/Contido.svg`}
                alt='Contido'
              />
            </span>
            <div>
              <h4>Contido</h4>
            </div>
          </ListItemStyles>

          <ListItemStyles
            className='application-btn'
            type='button'
            onClick={() => {
              openSidebar({
                title: 'Sprinklr',
                type: sidebarTypes.sprinklrIntegration,
              });
            }}>
            <span>
              <img src={SprinklrIcon} alt='Sprinklr' />
            </span>
            <div>
              <h4>Sprinklr</h4>
            </div>
          </ListItemStyles>
        </ListContainerStyles>
      </Body>
    </>
  );
};

Integration.propTypes = {
  toggleSidebar: PropTypes.func,
};

export default Integration;
