/* eslint-disable camelcase */
import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Provider,
  ErrorBoundary as RollbarErrorBoundary,
} from '@rollbar/react';

import { chameleonIdentifyUser, chameleonInit } from '../utils/chameleon';
import Wrapper from './main-wrapper';
import PUBLIC_ROUTES from '../routes/public';
import PRIVATEROUTES from '../routes/private';
// import { freshSupportChatService } from './portal/duck/operations';

// notification
import { db, NOTIFICATION_TRACKER } from './Firebase';

// Styles
import GlobalStyle from './styles/global';

// Application Level
import Upload from './components/upload';
import SomethingWentWrong from './portal/somethingWentWrong';
import Snackbar from './snackbar/Snackbar';

const rollbarConfig = {
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
  environment: process.env.ENVIRONMENT,
};

const App = props => {
  const userDetails = useSelector(store => store.common?.userDetails);
  // const cloudMachineDetails = useSelector(
  //   store => store.common?.requestedCloudSystemDetails
  // );
  // const storyboardDetails = useSelector(store => store.storyboard);
  const location = useLocation();
  const { uploadFiles } = props;
  const [notification, setNotification] = useState('');
  const [isUploadInProgress, setUploadInProgress] = useState(false);

  const disableRightClick = e => e.preventDefault();

  const disableDevToolsFromShortcuts = e => {
    const keyCode = e?.keyCode || e?.which;
    if (keyCode === 123) {
      e.preventDefault();
    }
  };

  useLayoutEffect(() => {
    const onTwakLoad = () => {
      if (
        window?.Tawk_API?.showWidget &&
        typeof window?.Tawk_API?.showWidget === 'function'
      ) {
        window.Tawk_API.showWidget();
      }
    };

    const initiateTwak = async () => {
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = process.env.TWAK_API_ENDPOINT;
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    };

    initiateTwak();
    setTimeout(onTwakLoad, 2000);
  }, []);

  useEffect(() => {
    window.addEventListener('contextmenu', disableRightClick);
    document.addEventListener('keydown', disableDevToolsFromShortcuts);
    return () => {
      window.removeEventListener('contextmenu', disableRightClick);
      document.removeEventListener('keydown', disableDevToolsFromShortcuts);
    };
  }, []);

  const onUploadComplete = (id, data, request) => {
    const { updateUploadData, sendUploadStatus } = props;
    updateUploadData(id, data); // to update reducuer, id = ingest_db_id
    const { folder_path, upload_id, parts } = request;
    const api = {
      project_db_id: data.project_db_id,
      data: {
        id,
        upload_status: data.status,
        folder_path,
        upload_id,
        parts,
      },
    };
    sendUploadStatus(api); // complete API
  };

  const onUploadError = (id, data) => {
    const { updateUploadData } = props;
    updateUploadData(id, data);
  };

  const onUploadProgress = (id, data) => {
    const { updateUploadData } = props;
    updateUploadData(id, data);
  };

  const onUploadCancel = (id, type, request) => {
    const { removeUploadData, sendUploadStatus } = props;
    removeUploadData({ id });
    if (type === 'user_cancel') {
      const apiData = {
        project_db_id: '',
        data: {
          upload_status: 'cancel',
          id,
          ...request,
        },
      };
      sendUploadStatus(apiData);
    }
  };

  const unloadCallback = useCallback(
    e => {
      if (isUploadInProgress) {
        const event = e || window.event;
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
      return null;
    },
    [isUploadInProgress, setUploadInProgress]
  );

  useEffect(() => {
    const isUpload =
      uploadFiles?.length > 0 &&
      uploadFiles.some(
        item => item.status === 'inprogress' || item.status === 'pending'
      );
    setUploadInProgress(isUpload);
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [uploadFiles, isUploadInProgress]);

  useEffect(() => {
    // const { history, toggleSidebar } = props;
    // const unlisten = history.listen(() => {
    //   toggleSidebar({ show: false });
    // });
    return () => {
      db.ref(NOTIFICATION_TRACKER).off();
      // if (unlisten) {
      //   unlisten();
      // }
    };
  }, []);

  const clearNotification = () => {
    db.ref(`${NOTIFICATION_TRACKER}/${userDetails?.user_id}`).update({
      notification: '',
    });
  };

  useEffect(() => {
    if (userDetails) {
      db.ref(`${NOTIFICATION_TRACKER}/${userDetails?.user_id}/notification`).on(
        'value',
        snapshot => {
          const value = snapshot.val();
          if (value) setNotification(value);
        }
      );

      clearNotification();
    }
  }, [userDetails?.user_id]);

  useEffect(() => {
    const { toggleToast } = props;
    if (notification) {
      toggleToast({
        message: {
          messageHead: 'New notification',
          messageBody: notification,
          variant: 'info',
        },
      });
    }
  }, [notification]);

  useEffect(() => {
    if (userDetails) {
      chameleonIdentifyUser(userDetails?.ps_user_id || userDetails?.username, {
        email: userDetails?.username,
        name: userDetails?.name,
        first_name: userDetails?.first_name,
        last_name: userDetails?.last_name,
        Plan: userDetails?.subscription,
        page: location?.pathname,
      });
    }
  }, [location, userDetails]);

  useEffect(() => {
    chameleonInit();
  }, []);

  return (
    <>
      <Provider config={rollbarConfig}>
        <GlobalStyle />
        <RollbarErrorBoundary fallbackUI={SomethingWentWrong}>
          <Snackbar />
          {uploadFiles.length > 0 &&
            uploadFiles.map(item => {
              return (
                <Upload
                  {...item}
                  key={`${item.ingest_db_id}${item.file.name}`}
                  onUploadComplete={(res, request) =>
                    onUploadComplete(item.ingest_db_id, res, request)
                  }
                  onUploadError={res => onUploadError(item.ingest_db_id, res)}
                  onUploadProgress={res =>
                    onUploadProgress(item.ingest_db_id, res)
                  }
                  onUploadCancel={(res, request) =>
                    onUploadCancel(item.ingest_db_id, res, request)
                  }
                />
              );
            })}
          <Switch>
            {PRIVATEROUTES.map(privateRoute => {
              return privateRoute.component ? (
                <Route
                  exact={privateRoute.exact}
                  path={privateRoute.path}
                  key={privateRoute.path}
                  render={() => <Wrapper component={privateRoute.component} />}
                />
              ) : null;
            })}
            {PUBLIC_ROUTES.map(publicRoute => {
              return (
                <Route
                  key={publicRoute.path}
                  path={publicRoute.path}
                  component={publicRoute.component}
                />
              );
            })}
          </Switch>
        </RollbarErrorBoundary>
      </Provider>
    </>
  );
};

App.propTypes = {
  uploadFiles: PropTypes.instanceOf(Array),
  userDetails: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  updateUploadData: PropTypes.func,
  removeUploadData: PropTypes.func,
  toggleSidebar: PropTypes.func,
  sendUploadStatus: PropTypes.func,
  toggleToast: PropTypes.func,
};

export default App;
