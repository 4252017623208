/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Close, ArrowBackIos } from '@material-ui/icons';

// Constants
import sidebarTypes from './constants';

// Components
import {
  Brief,
  Task,
  Collaborate,
  TaskDetails,
  ManageProject,
  RequestApproval,
  RequestReview,
  CloudAccess,
  Permissions,
  ShareAsset,
  Chat,
  TransferTask,
  LiveCollaboration,
  ProjectChat,
  ProjectHelp,
  Metadata,
  CameraToEdit,
  // FetchToUploadRawFiles,
} from '../../storyboard/components';

import {
  Plans,
  AddStorage,
  AddCredits,
  UseCredits,
  PlanDetails as SubscriptionPlanDetails,
  RenewalDetails as SubscriptionRenewalDetails,
  StorageDetails as SubscriptionStorageDetails,
} from '../../subscription/components';

import { ProfileSettings, AccountSettings } from '../homeSidebars';

import {
  CreateTeam,
  BillingDetails,
  TeamMembers as SettingsTeamMembers,
  Team as SettingsTeam,
  TeamMemberPermission as SettingsTeamMemberPermission,
  Integration as TeamSettingsIntegration,
} from '../../settings/components';
import S3Integration from '../../settings/components/integration/s3';
import SprinklrIntegration from '../../settings/components/integration/sprinklr';
import ContidoIntegration from '../../settings/components/integration/contido';

import Notification from '../notification';

// Styles
import { Sidebar as Main, Backdrop, Header } from '../../styles/sidebar';

const SidebarComponent = props => {
  const {
    show,
    componentProps,
    type,
    title,
    toggleSidebar,
    history,
    location,
    match,
    closeCallback,
    loadCollaborators,
    members,
    tasks,
    selectedTask,
    details,
    getUsersBasedOnFilters,
    getCollaborators,
    updateCollaboratedUsers,
    sendBrief,
    createUpdateTaskRequest,
    loadSelectedTaskDetails,
    createUpdateFetchTask,
    loadAllTaskDetails,
    assignToUsers,
    getAssignToUsers,
    getTaskTypes,
    getSystemResources,
    getSystems,
    isResourceAvailable,
    cloudAccessDetails,
    extendSession,
    createCancelQueueRequest,
    systemSession,
    createTransferRequest,
    toggleToast,
    userDetails,
    getUpdateMemberPermissions,
    shareAsset,
    shareAssetSettings,
    toggleUpgradeModal,
    addGetTaskMessages,
    loadTaskMessages,
    getNiceDcvSdkDownloadLink,
    goBack,
    completeTask,
    pusher,
  } = props;

  const onClose = () => {
    if (closeCallback) {
      closeCallback();
    }
    toggleSidebar({ show: false });
  };

  const renderSidebars = () => {
    let sidebar = null;

    const commonProps = {
      closeSidebar: onClose,
      toggleSidebar, // pass this to trigger sidebar inside another sidebar
      history,
      match,
      location,
      toggleToast,
      creatorDetails: { ...details },
      userDetails,
      toggleUpgradeModal,
      pusher,
      ...componentProps, // be careful - this should only contain static props
    };

    switch (type) {
      // APP SIDEBARS
      case sidebarTypes.profileSettings:
        sidebar = <ProfileSettings {...commonProps} />;
        break;

      case sidebarTypes.accountSettings:
        sidebar = <AccountSettings {...commonProps} />;
        break;

      case sidebarTypes.notification:
        sidebar = <Notification {...commonProps} />;
        break;

      // STORYBOARD SIDEBARS
      case sidebarTypes.addUpdateBrief:
        sidebar = (
          <Brief
            sendBrief={sendBrief}
            {...commonProps}
            selectedTask={selectedTask.details}
          />
        );
        break;
      case sidebarTypes.createTask:
        sidebar = (
          <Task
            getAssignToUsers={getAssignToUsers}
            assignToUsers={assignToUsers}
            getTaskTypes={getTaskTypes}
            selectedTask={selectedTask.details}
            createUpdateFetchTask={createUpdateFetchTask}
            getUsersBasedOnFilters={getUsersBasedOnFilters}
            getCollaborators={getCollaborators}
            loadCollaborators={loadCollaborators}
            {...commonProps}
          />
        );
        break;
      case sidebarTypes.addUpdateCollaborators:
        sidebar = (
          <Collaborate
            getUsersBasedOnFilters={getUsersBasedOnFilters}
            getCollaborators={getCollaborators}
            updateCollaboratedUsers={updateCollaboratedUsers}
            loadCollaborators={loadCollaborators}
            members={members} // list of collaborators
            {...commonProps}
          />
        );
        break;
      case sidebarTypes.taskDetails:
        sidebar = (
          <TaskDetails
            loadSelectedTaskDetails={loadSelectedTaskDetails}
            createUpdateFetchTask={createUpdateFetchTask}
            loadAllTaskDetails={loadAllTaskDetails}
            tasks={tasks}
            selectedTask={selectedTask?.details}
            updateCollaboratedUsers={updateCollaboratedUsers}
            completeTask={completeTask}
            {...commonProps}
          />
        );
        break;

      case sidebarTypes.transferTask:
        sidebar = (
          <TransferTask
            {...commonProps}
            createTransferRequest={createTransferRequest}
            loadSelectedTaskDetails={loadSelectedTaskDetails}
          />
        );
        break;

      case sidebarTypes.requestApproval:
        sidebar = (
          <RequestApproval
            getUsersBasedOnFilters={getUsersBasedOnFilters}
            createUpdateTaskRequest={createUpdateTaskRequest}
            {...commonProps}
          />
        );
        break;

      case sidebarTypes.requestReview:
        sidebar = (
          <RequestReview
            getUsersBasedOnFilters={getUsersBasedOnFilters}
            createUpdateTaskRequest={createUpdateTaskRequest}
            {...commonProps}
          />
        );
        break;

      case sidebarTypes.manageProject:
        sidebar = <ManageProject {...commonProps} sendBrief={sendBrief} />;
        break;

      case sidebarTypes.metadata:
        sidebar = <Metadata {...commonProps} />;
        break;

      case sidebarTypes.cloudAccess:
        sidebar = (
          <CloudAccess
            getSystemResources={getSystemResources}
            getSystems={getSystems}
            isResourceAvailable={isResourceAvailable}
            selectedTask={selectedTask?.details}
            cloudAccessDetails={cloudAccessDetails}
            extendSession={extendSession}
            createCancelQueueRequest={createCancelQueueRequest}
            systemSession={systemSession}
            getNiceDcvSdkDownloadLink={getNiceDcvSdkDownloadLink}
            {...commonProps}
          />
        );
        break;

      case sidebarTypes.permissions:
        sidebar = (
          <Permissions
            getUpdateMemberPermissions={getUpdateMemberPermissions}
            {...commonProps}
          />
        );
        break;

      case sidebarTypes.shareAsset:
        sidebar = (
          <ShareAsset
            shareAsset={shareAsset}
            getUsersBasedOnFilters={getUsersBasedOnFilters}
            fetchSettings={shareAssetSettings}
            {...commonProps}
          />
        );
        break;

      case sidebarTypes.chat:
        sidebar = (
          <Chat
            {...commonProps}
            addGetTaskMessages={addGetTaskMessages}
            loadTaskMessages={loadTaskMessages}
          />
        );
        break;

      case sidebarTypes.projectChat:
        sidebar = <ProjectChat {...commonProps} />;
        break;

      case sidebarTypes.projectHelp:
        sidebar = <ProjectHelp {...commonProps} />;
        break;

      case sidebarTypes.subscriptionPlans: {
        sidebar = <Plans {...commonProps} />;
        break;
      }

      case sidebarTypes.subscriptionPlanDetails: {
        sidebar = <SubscriptionPlanDetails {...commonProps} />;
        break;
      }

      case sidebarTypes.subscriptionRenewalDetails: {
        sidebar = <SubscriptionRenewalDetails {...commonProps} />;
        break;
      }

      case sidebarTypes.subscriptionStorageDetails: {
        sidebar = <SubscriptionStorageDetails {...commonProps} />;
        break;
      }

      case sidebarTypes.addStorage: {
        sidebar = <AddStorage {...commonProps} />;
        break;
      }

      case sidebarTypes.addCredits: {
        sidebar = <AddCredits {...commonProps} />;
        break;
      }

      case sidebarTypes.useCredits: {
        sidebar = <UseCredits {...commonProps} />;
        break;
      }

      case sidebarTypes.settingsCreateTeam: {
        sidebar = (
          <CreateTeam
            {...commonProps}
            getUsersBasedOnFilters={getUsersBasedOnFilters}
          />
        );
        break;
      }

      case sidebarTypes.settingsBillingDetails: {
        sidebar = <BillingDetails {...commonProps} />;
        break;
      }

      case sidebarTypes.settingsTeamMembers: {
        sidebar = <SettingsTeamMembers {...commonProps} />;
        break;
      }

      case sidebarTypes.settingsTeamMemberPermissions: {
        sidebar = <SettingsTeamMemberPermission {...commonProps} />;
        break;
      }

      case sidebarTypes.settingsTeam: {
        sidebar = <SettingsTeam {...commonProps} />;
        break;
      }

      case sidebarTypes.integration: {
        sidebar = <TeamSettingsIntegration {...commonProps} />;
        break;
      }

      case sidebarTypes.s3Integration: {
        sidebar = <S3Integration {...commonProps} />;
        break;
      }

      case sidebarTypes.sprinklrIntegration: {
        sidebar = <SprinklrIntegration {...commonProps} />;
        break;
      }

      case sidebarTypes.contidoIntegration: {
        sidebar = <ContidoIntegration {...commonProps} />;
        break;
      }

      case sidebarTypes.liveCollaboration: {
        sidebar = <LiveCollaboration {...commonProps} />;
        break;
      }

      case sidebarTypes.cameraToEdit: {
        sidebar = <CameraToEdit.Sidebar {...commonProps} />;
        break;
      }

      // case sidebarTypes.fetchToUploadRawFiles: {
      //   sidebar = <FetchToUploadRawFiles {...commonProps} />;
      //   break;
      // }

      default: {
        sidebar = null;
        break;
      }
    }
    return sidebar;
  };

  return (
    <div>
      <Backdrop show={show} onClick={onClose} />
      <Main show={show}>
        <div className='inner'>
          <div className='holder'>
            <Header>
              <div className='flex items-center'>
                {goBack && (
                  <ArrowBackIos
                    style={{ marginRight: '0.5rem' }}
                    onClick={goBack}
                  />
                )}
                {typeof title === 'string' ? <h3>{title}</h3> : title}
              </div>
              <Close onClick={onClose} />
            </Header>
            {renderSidebars()}
          </div>
        </div>
      </Main>
    </div>
  );
};

SidebarComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  selectedTask: PropTypes.instanceOf(Object),
  assets: PropTypes.instanceOf(Object),
  show: PropTypes.bool,
  match: PropTypes.instanceOf(Object),
  componentProps: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  goBack: PropTypes.func,
  members: PropTypes.instanceOf(Object),
  tasks: PropTypes.instanceOf(Object),
  details: PropTypes.instanceOf(Object),
  getUsersBasedOnFilters: PropTypes.func,
  getCollaborators: PropTypes.func,
  updateCollaboratedUsers: PropTypes.func,
  sendBrief: PropTypes.func,
  createUpdateTaskRequest: PropTypes.func,
  loadSelectedTaskDetails: PropTypes.func,
  createUpdateFetchTask: PropTypes.func,
  loadAllTaskDetails: PropTypes.func,
  assignToUsers: PropTypes.func,
  getAssignToUsers: PropTypes.func,
  getTaskTypes: PropTypes.func,
  getSystemResources: PropTypes.func,
  getSystems: PropTypes.func,
  isResourceAvailable: PropTypes.func,
  cloudAccessDetails: PropTypes.func,
  extendSession: PropTypes.func,
  createCancelQueueRequest: PropTypes.func,
  systemSession: PropTypes.func,
  createTransferRequest: PropTypes.func,
  completeTask: PropTypes.func,
  toggleToast: PropTypes.func,
  userDetails: PropTypes.func,
  getUpdateMemberPermissions: PropTypes.func,
  shareAsset: PropTypes.func,
  shareAssetSettings: PropTypes.func,
  toggleUpgradeModal: PropTypes.func,
  addGetTaskMessages: PropTypes.func,
  loadTaskMessages: PropTypes.func,
  getNiceDcvSdkDownloadLink: PropTypes.func,
  toggleSidebar: PropTypes.func,
  closeCallback: PropTypes.func,
  loadCollaborators: PropTypes.func,
  pusher: PropTypes.instanceOf(Object),
};

export default SidebarComponent;
