import {
  LOAD_PROJECT_DETAILS,
  LOAD_TRANSFER_FILES,
  TOGGLE_FETCHING_STATE,
} from './types';

const INITIAL_STATE = {
  transferFiles: null,
  isFetching: false,
  selectedProject: null,
};

export default function importTransferReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_TRANSFER_FILES: {
      const { results, type } = action.payload;

      if (type === 'pagination') {
        const updated = { ...state?.transferFiles };
        updated.page_no = results?.page_no;
        updated.transfers = [
          ...state?.transferFiles?.transfers,
          ...results?.transfers,
        ];
        return {
          ...state,
          transferFiles: { ...updated },
        };
      }

      return {
        ...state,
        transferFiles: results || null,
      };
    }

    case TOGGLE_FETCHING_STATE: {
      const { toggleState } = action.payload;
      return {
        ...state,
        isFetching: toggleState,
      };
    }

    case LOAD_PROJECT_DETAILS: {
      const { results } = action.payload;
      const { title, team_db_id } = results;
      return {
        ...state,
        selectedProject: { title, team_db_id },
      };
    }

    default:
      return state;
  }
}
