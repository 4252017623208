import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Checkbox } from '../../components/FormInputs';

import { selectItemsAction } from '../duck/actions';
import { SELECTION_KEYS } from '../constants';
import Tooltip from '../../components/tooltip';

const defaultProps = {
  disabled: false,
};

export default function ListSelectAll({
  isSelectAll,
  onResetSelectAll,
  disabled,
}) {
  const dispatch = useDispatch();
  const selectedItems = useSelector(store => store?.storyboard?.selectedItems);
  const results = useSelector(store => store?.storyboard?.assets?.results);

  const onSelect = useCallback(
    e => {
      const assets = results.reduce((acc, curr) => {
        const path = curr?.folder_path?.split('/')?.slice(1)?.join('/');
        acc[curr.ingest_db_id] = {
          ingest_db_id: curr.ingest_db_id,
          is_folder: curr.is_folder,
          file_type: curr.file_type,
          trans_status: curr.ingest_db_id,
          upload_status: curr.ingest_db_id,
          uploaded_from: curr.ingest_db_id,
          title: path ? `${path}/${curr.title}` : curr.title,
        };
        return acc;
      }, {});

      if (e.target.checked) {
        onResetSelectAll(true);
        dispatch(
          selectItemsAction({
            type: SELECTION_KEYS.all,
            data: { ...assets },
          })
        );
      } else {
        onResetSelectAll(false);
        dispatch(
          selectItemsAction({
            type: SELECTION_KEYS.reset,
          })
        );
      }
    },
    [results]
  );

  return (
    <div className='flex items-center'>
      <Tooltip title='Select All Assets' hide placement='bottom' arrow>
        <Checkbox
          disabled={disabled}
          aria-label='select-all'
          onChange={onSelect}
          checked={
            selectedItems
              ? Object.keys(selectedItems)?.length === results?.length
              : isSelectAll
          }
        />
      </Tooltip>
      <p style={{ flex: '1', marginLeft: '1rem' }}>Asset Name</p>
    </div>
  );
}

ListSelectAll.propTypes = {
  disabled: PropTypes.bool,
  isSelectAll: PropTypes.bool,
  onResetSelectAll: PropTypes.func,
};

ListSelectAll.defaultProps = defaultProps;
