import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Normal } from '../../../../components/Button';
import {
  TextField,
  Select,
  useForm,
  Checkbox,
} from '../../../../components/FormInputs';

// Helper Functions
import {
  required,
  bucketRegex,
  bucketFolderPathRegex,
} from '../../../../components/FormInputs/helperFunctions';

// service calls
import { contidoIntegrationService } from '../../../duck/operations';

// constants
import { CONTIDO_STEPS } from '../../../constants/integration';

// styles
import { Body, Footer } from '../../../../styles/sidebar';
import { Text, SwitchStyles } from '../../../../styles/common';
import { whiteAlpha } from '../../../../styles/utils';
import StepsCarousel from '../../../../components/stepsCarousel';

const INITIAL_STATE = {
  region: {
    value: '',
    error: undefined,
    required: false,
    validation: required,
  },

  input_bucket: {
    value: '',
    error: undefined,
    required: false,
    validation: value => bucketRegex(value),
  },

  input_bucket_folder: {
    value: '',
    error: undefined,
    required: false,
    validation: value => bucketFolderPathRegex(value),
  },

  output_bucket: {
    value: '',
    error: undefined,
    required: false,
    validation: value => bucketRegex(value),
  },

  output_bucket_folder: {
    value: '',
    error: undefined,
    required: false,
    validation: value => bucketFolderPathRegex(value),
  },
};

const CreateEdit = ({ goTo, toggleToast, selectBucketId }) => {
  const selectedTeam = useSelector(store => store?.common.selectedTeam);
  const [isDateFoldersChecked, toggleDateFoldersCheck] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [pathCheckbox, setPathCheckbox] = useState({
    input: false,
    output: false,
  });

  const { formValues, onChange, onSubmit, updateValues } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);
      contidoIntegrationService(
        {
          team_db_id: selectedTeam?.team_db_id,
          team_id: selectedTeam?.team_db_id,
          region: formValues.region.value,
          create_date_folders: isDateFoldersChecked,

          ...(formValues.input_bucket.value && {
            input_bucket: formValues.input_bucket.value,
          }),

          ...(formValues.input_bucket_folder.value && {
            input_bucket_folder: formValues.input_bucket_folder.value,
          }),

          ...(formValues.output_bucket.value && {
            output_bucket: formValues.output_bucket.value,
          }),

          ...(formValues.output_bucket_folder.value && {
            output_bucket_folder: formValues.output_bucket_folder.value,
          }),
        },
        'post'
      )
        .then(res => {
          if (res?.data?.response) {
            setSubmitting(false);
            selectBucketId(res?.data?.data?.integration?.integration_db_id);
            goTo(CONTIDO_STEPS.bucketDetails);
            toggleToast({
              message: {
                messageHead: 'Success',
                messageBody: res?.data?.data?.message,
                variant: 'success',
              },
            });
          } else throw new Error(res?.data?.errormessage);
        })
        .catch(err => {
          setSubmitting(false);
          toggleToast({
            message: {
              messageHead: 'Error',
              messageBody: err?.message,
              variant: 'error',
            },
          });
        });
    }
  );

  useEffect(() => {
    if (selectedTeam?.aws_config?.region) {
      updateValues({
        ...formValues,
        region: {
          ...formValues.region,
          value: selectedTeam?.aws_config?.region_name,
        },
      });
    }
  }, [selectedTeam]);

  const onSelectPath = (event, path = 'input') => {
    const { checked } = event?.target;
    const updated = { ...pathCheckbox };
    const updatedFields = {
      ...formValues,
      input_bucket: { ...formValues.input_bucket },
      output_bucket: { ...formValues.output_bucket },
    };

    if (checked) {
      updated[path] = true;
      if (path === 'output') {
        updatedFields.output_bucket.required = true;
        updateValues(updatedFields);
      } else {
        updatedFields.input_bucket.required = true;
        updateValues(updatedFields);
      }
    } else {
      updated[path] = false;
      if (path === 'output') {
        updatedFields.output_bucket.required = false;
        updatedFields.output_bucket.error = undefined;
        updateValues(updatedFields);
      } else {
        updatedFields.input_bucket.required = false;
        updatedFields.input_bucket.error = undefined;
        updateValues(updatedFields);
      }
    }
    setPathCheckbox(updated);
  };

  return (
    <>
      <Body>
        <StepsCarousel
          options={[
            'Create an S3 bucket in <region> AWS Region or use an existing bucket.',
            'Enable the ACLs on the bucket and set S3 object ownership to bucket owner preferred.',
            'Attach the bucket policy statement to grant access to Postudio to access the objects in your bucket.',
          ]}
        />

        <Select
          id='region'
          label='Region'
          value={formValues.region.value}
          onChange={onChange}
          error={!!formValues.region.error}
          helperText={formValues.region.error}
          disabled
          options={[
            {
              label: `${selectedTeam?.aws_config?.region} (${selectedTeam?.aws_config?.region_name})`,
              value: selectedTeam?.aws_config?.region_name,
            },
          ]}
        />

        <div aria-label='input-path' style={{ margin: '1rem 0' }}>
          <div
            className='flex items-center v-padd'
            style={{ columnGap: '1rem' }}>
            <Checkbox
              aria-label='input-checkbox'
              checked={pathCheckbox?.input}
              value='input-checkbox'
              onChange={e => onSelectPath(e)}
            />
            <div>
              <Text padding='0'>Input Path</Text>
              <Text padding='0.3rem 0 0 0' color={whiteAlpha(0.6)} sm>
                To import assets to your Postudio Projects from the external S3
                Bucket
              </Text>
            </div>
          </div>
          {pathCheckbox?.input && (
            <>
              <TextField
                id='input_bucket'
                label='S3 Bucket Name'
                value={formValues.input_bucket.value}
                onChange={onChange}
                error={!!formValues.input_bucket.error}
                helperText={formValues.input_bucket.error}
              />
              <TextField
                id='input_bucket_folder'
                label='Folder Path'
                value={formValues.input_bucket_folder.value}
                onChange={onChange}
                error={!!formValues.input_bucket_folder.error}
                helperText={formValues.input_bucket_folder.error}
              />
            </>
          )}
        </div>

        <div aria-label='output-path'>
          <div
            className='flex items-center v-padd'
            style={{ columnGap: '1rem' }}>
            <Checkbox
              aria-label='output-checkbox'
              checked={pathCheckbox?.output}
              value='output-checkbox'
              onChange={e => onSelectPath(e, 'output')}
            />
            <div>
              <Text padding='0'>Output Path</Text>
              <Text padding='0.3rem 0 0 0' color={whiteAlpha(0.6)} sm>
                To share assets from your Postudio Projects to the external S3
                Bucket
              </Text>
            </div>
          </div>
          {pathCheckbox?.output && (
            <>
              <TextField
                id='output_bucket'
                label='S3 Bucket Name'
                value={formValues.output_bucket.value}
                onChange={onChange}
                error={!!formValues.output_bucket.error}
                helperText={formValues.output_bucket.error}
              />
              <TextField
                id='output_bucket_folder'
                label='Folder Path'
                value={formValues.output_bucket_folder.value}
                onChange={onChange}
                error={!!formValues.output_bucket_folder.error}
                helperText={formValues.output_bucket_folder.error}
              />
              <div
                aria-label='date-creation'
                className='flex items-center justify-between v-padd'>
                <div>
                  <Text padding='0'>Date Folders</Text>
                  <Text padding='0.3rem 1.5rem 0 0' color={whiteAlpha(0.6)} sm>
                    To keep the exported output files in date-wise folders in
                    your S3 Bucket
                  </Text>
                </div>
                <SwitchStyles
                  size='small'
                  color='primary'
                  checked={isDateFoldersChecked}
                  onChange={e => toggleDateFoldersCheck(e?.target?.checked)}
                />
              </div>
            </>
          )}
        </div>
      </Body>
      <Footer
        style={{
          columnGap: pathCheckbox?.input || pathCheckbox?.output ? '2rem' : '',
        }}>
        <Normal
          type='button'
          onClick={() => goTo(CONTIDO_STEPS.integratedRegions)}
          color='secondary'>
          Back
        </Normal>
        {(pathCheckbox?.input || pathCheckbox?.output) && (
          <Normal type='submit' onClick={onSubmit} isLoading={isSubmitting}>
            Create
          </Normal>
        )}
      </Footer>
    </>
  );
};

CreateEdit.propTypes = {
  goTo: PropTypes.func,
  toggleToast: PropTypes.func,
  selectBucketId: PropTypes.func,
};

export default CreateEdit;
