import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { Normal } from '../../../../components/Button';
import Modal from '../../../../components/modal';

import { contidoIntegrationService } from '../../../duck/operations';
import { enqueueSnackbar } from '../../../../snackbar/duck/actions';

import { CONTIDO_STEPS } from '../../../constants/integration';

// styles
import { Body, Footer } from '../../../../styles/modal';
import { Text } from '../../../../styles/common';

const BucketDeleteModal = ({
  integration_db_id,
  toggleModal,
  open,
  bucketDetails,
  goTo,
}) => {
  const dispatch = useDispatch();
  const selectedTeam = useSelector(store => store?.common?.selectedTeam);
  const [isSubmitting, setSubmitting] = useState(false);

  const onDelete = () => {
    setSubmitting(true);
    contidoIntegrationService(
      {
        team_db_id: selectedTeam?.team_db_id,
        team_id: selectedTeam?.team_db_id,
        integration_db_id,
      },
      'delete'
    )
      .then(res => {
        if (res?.data?.response) {
          goTo(CONTIDO_STEPS.integratedRegions);
          setSubmitting(false);
          dispatch(
            enqueueSnackbar({
              message: {
                messageHead: 'Success',
                messageBody: res?.data?.data?.message,
                variant: 'success',
              },
            })
          );
        } else throw new Error(res?.data?.errormessage);
      })
      .catch(err => {
        dispatch(
          enqueueSnackbar({
            message: {
              messageHead: 'Error',
              messageBody: err?.message,
              variant: 'error',
            },
          })
        );
      });
  };

  const onClose = () => toggleModal(false);

  return (
    <>
      <Modal onClose={onClose} open={open} title='Delete Bucket'>
        <Body>
          <div style={{ display: 'grid', rowGap: '0.5rem' }}>
            {bucketDetails?.input_bucket && (
              <Text padding='0' md>
                Input Bucket Name: {bucketDetails?.input_bucket}
              </Text>
            )}
            {bucketDetails?.output_bucket && (
              <Text padding='0' md>
                Output Bucket Name: {bucketDetails?.output_bucket}
              </Text>
            )}
          </div>
        </Body>
        <Footer>
          <Normal
            id='delete-asset-no'
            onClick={onClose}
            color='secondary'
            style={{ marginRight: '2rem' }}>
            No
          </Normal>
          <Normal
            id='delete-asset-yes'
            onClick={onDelete}
            isLoading={isSubmitting}>
            Yes
          </Normal>
        </Footer>
      </Modal>
    </>
  );
};

BucketDeleteModal.propTypes = {
  integration_db_id: PropTypes.string,
  toggleModal: PropTypes.func,
  bucketDetails: PropTypes.instanceOf(Object),
  open: PropTypes.bool,
  goTo: PropTypes.func,
};

export default BucketDeleteModal;
