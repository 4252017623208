import styled, { css } from 'styled-components';
import { Avatar, Badge } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Switch from '@material-ui/core/Switch';

// Styles
import { padding, colors, font, fs, radius, devices } from './variables';
import { whiteAlpha } from './utils';

const CommonClasses = css`
  padding: ${props => props.padding};
  padding-left: ${props => props.paddingLeft};
  padding-right: ${props => props.paddingRight};
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};

  margin: ${props => props.margin};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
`;

const ContainerFluid = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
`;

const CardGrid = styled.div`
  position: relative;
  padding: ${padding.v} 2rem;
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
`;

const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Label = styled.div`
  font-size: 1.4rem;
  color: ${({ color }) => color || colors.white};
  line-height: 1.4;
  margin: ${({ margin }) => margin};
`;

const Text = styled.p`
  ${CommonClasses};
  font-size: ${({ xs, sm, md, bg }) => {
    if (xs) {
      return '1rem';
    }
    if (sm) {
      return '1.2rem';
    }
    if (md) {
      return '1.6rem';
    }
    if (bg) {
      return '1.8rem';
    }
    return fs.sm;
  }};

  color: ${({ color }) => color || colors.white};
  line-height: ${props => props.lineHeight || '1.4'};
  font-weight: ${({ bold, semibold, medium }) => {
    if (bold) {
      return font.bold;
    }
    if (semibold) {
      return font.semibold;
    }
    if (medium) {
      return font.medium;
    }
    return font.normal;
  }};
`;

const SubHead = styled.div`
  font-size: 1.8rem;
  color: ${({ color }) => color || colors.white};
  font-weight: ${font.normal};
`;

const TextButton = styled.button`
  font-size: 1.2rem;
  color: ${({ color }) => color || colors.white};
  background-color: transparent;
  cursor: pointer;
  border: none;
  text-align: left;
  font-weight: ${({ bold }) => (bold ? font.bold : font.normal)};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
`;

const DivStyles = styled.div`
  ${CommonClasses};
`;

const NoDetailsFound = styled(Flex)`
  position: relative;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 6rem;
  }

  .content {
    margin: 2rem 0;
    text-align: center;

    .head,
    .sub-head {
      font-size: ${fs.md};
      font-weight: 300;
    }

    .head {
      color: ${whiteAlpha(0.6)};
    }

    .sub-head {
      margin-top: 1.5rem;
      color: ${whiteAlpha(1)};
    }
  }
`;

const Container = styled.div`
  padding: 3rem;
  border-radius: 1rem;
  border: 0.1rem solid ${colors.blue.navy};
  transition: all 0.25s ease;
  margin-bottom: 4rem;
  flex: ${props => (props.flexGrow ? '1' : 'none')};
`;

const ComingSoon = styled.div`
  margin-bottom: 2rem;
  background: ${colors.yellow};
  border-radius: 2rem;
  color: ${colors.black.main};
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  width: 12rem;
  padding: 0.5rem;
`;

const TableViewContainerStyles = styled.div`
  padding: 1rem 2rem 0 2rem;

  .table-head {
    padding: ${padding.h} 2rem;
    color: ${whiteAlpha(0.8)};
  }

  .table-row {
    margin-top: 1rem;
    border: 1px solid #122a4a;
    border-radius: ${radius.default};

    .table-item {
      padding: ${padding.h} 2rem;
      color: ${colors.white};
    }
  }

  .table-head.last,
  .table-item.last {
    width: auto;
  }
`;

const IconBreadCrumbStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .MuiFab-label {
    display: inherit;
  }
  .MuiFab-root {
    width: 3rem;
    height: 3rem;
    min-height: 1rem;
    .MuiSvgIcon-root {
      font-size: 1.8rem;
      width: 1em;
      height: 1em;
    }
  }
`;

const ActionCardGridStyles = styled.div`
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 1.5rem;

  @media screen and (min-width: ${devices.md}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (min-width: ${devices.lg}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const CustomAvatarGroupStyles = styled(AvatarGroup)`
  .MuiAvatar-root {
    position: relative;
    border: none;
    width: 3rem;
    height: 3rem;
    font-size: 1.2rem;
    background: #1d4376;
    color: ${colors.white};
    ${({ length }) =>
      length > 4 &&
      css`
        &:last-child {
          background-color: transparent;
          color: ${colors.blue.shadeTwo};
          margin-left: 0px;
        }
      `};
  }
`;

const CustomAvatarStyles = styled(Avatar)`
  &.MuiAvatar-root {
    position: relative;
    width: 3rem;
    height: 3rem;
    font-size: 1.1rem;
    color: ${colors?.white};
    background: ${({ secondary, color }) =>
      secondary ? '#78909C' : color || colors.blue.shadeThree};
    ${({ border }) =>
      border &&
      css`
        border: 0.1rem solid ${colors.black.main};
      `};
  }
`;

const BadgeStyles = styled(Badge)`
  &.MuiBadge-root {
    width: 0.8rem;
    height: 0.8rem;
    background: #18b96e;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100%;
    border: 1px solid ${colors.white};
  }
`;

const AvatarUsernameStyles = styled.h3`
  font-weight: ${font.medium};
  padding: 0;
  margin-bottom: 0.3rem;
  font-size: 1.4rem;
  color: ${colors.white};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const AvatarSecondaryTextStyles = styled(Text)`
  font-size: ${({ secondary }) => secondary && '1.2rem'};
  color: ${whiteAlpha(0.8)};
  text-align: left;
  padding: 0;
`;

const PaginationWrapperStyles = styled.div`
  margin-top: 4rem;
  .MuiPagination-root {
    width: fit-content;
    margin: 0 auto;
    .MuiPaginationItem-root {
      color: ${colors?.white};
      font-size: 1.4rem;
      &.Mui-selected {
        background-color: ${colors?.blue?.primary};
      }
      &.Mui-disabled {
        color: ${whiteAlpha(0.5)};
      }
      .MuiSvgIcon-root {
        width: 2rem;
        height: 2rem;
      }
    }
  }
`;

const AppLinkButton = styled.button`
  color: ${colors.blue.shadeTwo};
  font-size: 1.4rem;
  background-color: transparent;
  border: none;
  font-weight: ${font.normal};
  cursor: pointer;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const CarouselViewParentStyles = styled.div`
  border: 0.1rem solid ${whiteAlpha(0.3)};
  border-radius: ${radius.default};
  margin-top: 2.5rem;
  padding: 1.5rem;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .indicator {
      margin-left: 0.75rem;
      border: none;
      cursor: pointer;
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      background-color: ${whiteAlpha(0.4)};

      &.active {
        background-color: ${colors.white};
      }
    }
  }
`;

export const ListKeyValueItemStyles = styled.li`
  list-style: none;
  p,
  h4 {
    font-weight: ${font.medium};
    padding: 0;
  }

  p {
    text-transform: uppercase;
    font-size: 1rem;
    color: ${whiteAlpha(0.6)};
    margin-bottom: 0.5rem;
  }

  h4 {
    color: ${colors.white};
    line-height: 1.4;
    font-size: ${fs.sm};
  }
`;

export const AnchorLinkStyles = styled.a`
  color: ${colors.blue.shadeTwo};
  font-size: 1.4rem;
  font-weight: ${font.normal};
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const NewAddButtonStyles = styled.button`
  column-gap: 0.5rem;
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
  border: 0.1rem solid ${colors.blue.shadeTwo};
  color: ${colors.blue.shadeTwo};
  width: 100%;

  h4 {
    color: ${colors.blue.shadeTwo};
    font-size: 1.6rem;
    font-weight: ${font.normal};
  }

  .icon {
    border: 0.1rem solid ${colors.blue.shadeTwo};
    border-radius: 100%;
    padding: 0.5rem;
    display: flex;

    .MuiSvgIcon-root {
      width: 2rem;
      height: 2rem;
      fill: ${colors.blue.shadeTwo};
    }
  }

  &:hover {
    color: ${colors.blue.primary};
    border-color: ${colors.blue.primary};

    .MuiSvgIcon-root {
      fill: ${colors.blue.primary};
    }

    h4 {
      color: ${colors.blue.primary};
    }
    .icon {
      border-color: ${colors.blue.primary};
    }
  }
`;

export const CopiedTextStyles = styled.span`
  font-size: ${fs.xs};
  border-radius: ${radius.default};
  background-color: ${whiteAlpha(0.15)};
  padding: 0.2rem 0.4rem;
  color: ${colors.white};
`;

export const SwitchStyles = styled(Switch)`
  .MuiSwitch-switchBase {
    padding: 5.8px;

    &.Mui-checked + .MuiSwitch-track {
      opacity: 1;
    }
  }

  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${colors.white};
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${colors.blue.shadeOne};
  }

  .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track {
    background-color: ${whiteAlpha(0.8)};
  }

  .MuiSwitch-track {
    background-color: ${whiteAlpha(0.8)};
  }

  .MuiSwitch-thumb {
    width: 12px;
    height: 12px;
  }
`;

export const BreadCrumbTextStyles = styled(Text)`
  &.first {
    color: ${whiteAlpha(0.7)};
    max-width: 8rem;
  }
  &.last {
    max-width: 20rem;
  }
`;

export const GridListParentStyles = styled.div`
  padding-bottom: 8.5rem;
`;

export {
  CardGrid,
  Flex,
  FlexWrap,
  ContainerFluid,
  Label,
  Text,
  SubHead,
  TextButton,
  NoDetailsFound,
  Container,
  ComingSoon,
  TableViewContainerStyles,
  IconBreadCrumbStyles,
  ActionCardGridStyles,
  DivStyles,
  CustomAvatarStyles,
  AvatarUsernameStyles,
  AvatarSecondaryTextStyles,
  CustomAvatarGroupStyles,
  PaginationWrapperStyles,
  AppLinkButton,
  CarouselViewParentStyles,
  BadgeStyles,
};
