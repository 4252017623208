import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FileCopyOutlined } from '@material-ui/icons';

// Components
import { Normal } from '../../../../components/Button';
import BucketDeleteModal from './BucketDeleteModal';

import { contidoIntegrationService } from '../../../duck/operations';
import { CONTIDO_STEPS } from '../../../constants/integration';

// styles
import { Body, Footer } from '../../../../styles/sidebar';
import {
  Text,
  AppLinkButton,
  ListKeyValueItemStyles,
  CopiedTextStyles,
} from '../../../../styles/common';
import { colors, fs, padding } from '../../../../styles/variables';
import { whiteAlpha } from '../../../../styles/utils';
import { IntegrationStatusStyles } from '../index';

const PolicyStyles = styled.div`
  height: 17rem;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 1rem;
  border-radius: 1rem;
  border: 0.1rem solid ${whiteAlpha(0.3)};
  color: ${colors.white};
  font-size: ${fs.sm};
  white-space: pre-wrap;
  padding: ${padding.v} ${padding.h};
`;

const CopiedTextStyled = styled(CopiedTextStyles)`
  margin-left: 1rem;
`;

const BucketDetails = ({ goTo, selectBucketId, selectedBucketId = null }) => {
  const [isModalOpen, toggleModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [details, setDetails] = useState(null);
  const selectedTeam = useSelector(store => store?.common.selectedTeam);

  useEffect(() => {
    if (selectedBucketId) {
      contidoIntegrationService({
        team_db_id: selectedTeam?.team_db_id,
        team_id: selectedTeam?.team_db_id,
        integration_db_id: selectedBucketId,
      })
        .then(res => {
          if (res?.data?.response) {
            setFetchingDetails(false);
            setDetails(res?.data?.data?.integration);
          } else throw new Error();
        })
        .catch(() => {
          setFetchingDetails(false);
          setDetails(null);
        });
    }

    return () => {
      selectBucketId(null);
      setDetails(null);
    };
  }, [selectedBucketId]);

  const onCopyText = policy => {
    navigator.clipboard.writeText(JSON.stringify(policy, null, 4)).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const onBack = () => goTo(CONTIDO_STEPS.integratedRegions);

  return (
    <>
      {fetchingDetails ? (
        <Body noFooter>
          <Text>Fetching bucket details</Text>
        </Body>
      ) : (
        <>
          {details && (
            <>
              <Body>
                <div className='grid gap-y-1-point-5'>
                  <div aria-label='input-bucket-details'>
                    <ul className='grid gap-y-1-point-5'>
                      <ListKeyValueItemStyles aria-label='region'>
                        <p>Region</p>
                        <h4>{`${details?.aws_config?.region} (${details?.aws_config?.region_name})`}</h4>
                      </ListKeyValueItemStyles>
                      {details?.input_bucket && (
                        <ListKeyValueItemStyles>
                          <p>Input Bucket Name</p>
                          <h4>{details?.input_bucket}</h4>
                        </ListKeyValueItemStyles>
                      )}
                      {details.input_folder_path && (
                        <ListKeyValueItemStyles>
                          <p>Input Folder Path</p>
                          <h4>{details?.input_folder_path}</h4>
                        </ListKeyValueItemStyles>
                      )}
                    </ul>
                  </div>

                  {Object.keys(details?.input_bucket_policy).length > 0 && (
                    <div aria-label='input-bucket-policy'>
                      <Text
                        color={whiteAlpha(0.6)}
                        padding='0'
                        xs
                        style={{ textTransform: 'uppercase' }}>
                        Input Bucket Policy
                      </Text>
                      <PolicyStyles id='bucketPolicyContent'>
                        {JSON.stringify(details?.input_bucket_policy, null, 4)}
                      </PolicyStyles>
                      <div
                        className='flex items-center justify-end'
                        style={{ marginTop: '1rem', textAlign: 'right' }}>
                        <AppLinkButton
                          onClick={() =>
                            onCopyText(details?.input_bucket_policy)
                          }>
                          <span
                            style={{
                              verticalAlign: 'middle',
                              marginRight: '0.5rem',
                            }}>
                            <FileCopyOutlined />
                          </span>
                          <span>Copy to Clipboard</span>
                        </AppLinkButton>
                        {copied && <CopiedTextStyled>Copied</CopiedTextStyled>}
                      </div>
                    </div>
                  )}

                  {(details?.output_bucket || details?.output_folder_path) && (
                    <div aria-label='output-bucket-details'>
                      <ul className='grid gap-y-1-point-5'>
                        {details?.output_bucket && (
                          <ListKeyValueItemStyles>
                            <p>Output Bucket Name</p>
                            <h4>{details?.output_bucket}</h4>
                          </ListKeyValueItemStyles>
                        )}
                        {details?.output_folder_path && (
                          <ListKeyValueItemStyles>
                            <p>Output Folder Path</p>
                            <h4>{details?.output_folder_path}</h4>
                          </ListKeyValueItemStyles>
                        )}
                      </ul>
                    </div>
                  )}

                  {Object.keys(details?.output_bucket_policy).length > 0 && (
                    <div aria-label='output-bucket-policy'>
                      <Text
                        color={whiteAlpha(0.6)}
                        padding='0'
                        xs
                        style={{ textTransform: 'uppercase' }}>
                        Output Bucket Policy
                      </Text>
                      <PolicyStyles id='bucketPolicyContent'>
                        {JSON.stringify(details?.output_bucket_policy, null, 4)}
                      </PolicyStyles>
                      <div
                        className='flex items-center justify-end'
                        style={{ marginTop: '1rem', textAlign: 'right' }}>
                        <AppLinkButton
                          onClick={() =>
                            onCopyText(details?.output_bucket_policy)
                          }>
                          <span
                            style={{
                              verticalAlign: 'middle',
                              marginRight: '0.5rem',
                            }}>
                            <FileCopyOutlined />
                          </span>
                          <span>Copy to Clipboard</span>
                        </AppLinkButton>
                        {copied && <CopiedTextStyled>Copied</CopiedTextStyled>}
                      </div>
                    </div>
                  )}

                  {/* common */}
                  {Object.keys(details?.bucket_policy).length > 0 && (
                    <div aria-label='common-bucket-policy'>
                      <Text
                        color={whiteAlpha(0.6)}
                        padding='0'
                        xs
                        style={{ textTransform: 'uppercase' }}>
                        Bucket Policy (Input & Output)
                      </Text>
                      <PolicyStyles id='commonBucketPolicyContent'>
                        {JSON.stringify(details?.bucket_policy, null, 4)}
                      </PolicyStyles>
                      <div
                        className='flex items-center justify-end'
                        style={{ marginTop: '1rem', textAlign: 'right' }}>
                        <AppLinkButton
                          onClick={() => onCopyText(details?.bucket_policy)}>
                          <span
                            style={{
                              verticalAlign: 'middle',
                              marginRight: '0.5rem',
                            }}>
                            <FileCopyOutlined />
                          </span>
                          <span>Copy to Clipboard</span>
                        </AppLinkButton>
                        {copied && <CopiedTextStyled>Copied</CopiedTextStyled>}
                      </div>
                    </div>
                  )}

                  <Text paddingLeft='0' paddingRight='0'>
                    Attach the bucket policy statement to your S3 account to
                    grant access to Postudio.
                  </Text>

                  <IntegrationStatusStyles
                    status={details?.status?.toLowerCase()}>
                    <Text>Integration Status: {details?.status}</Text>
                  </IntegrationStatusStyles>

                  <AppLinkButton
                    onClick={() => {
                      toggleModal(true);
                    }}
                    style={{ textAlign: 'left' }}>
                    Delete Integration
                  </AppLinkButton>
                </div>
              </Body>
              <Footer>
                <Normal onClick={onBack} color='secondary'>
                  Back
                </Normal>
              </Footer>
            </>
          )}
        </>
      )}
      {isModalOpen && (
        <BucketDeleteModal
          toggleModal={toggleModal}
          integration_db_id={selectedBucketId}
          open={isModalOpen}
          goTo={goTo}
          bucketDetails={details}
        />
      )}
    </>
  );
};

BucketDetails.propTypes = {
  selectedBucketId: PropTypes.string,
  selectBucketId: PropTypes.func,
  goTo: PropTypes.func,
};

export default BucketDetails;
