const MODAL_TYPES = {
  deleteAsset: 'DELETE_ASSET',
  deleteTask: 'DELETE_TASK',
  deleteStoryboard: 'DELETE_STORYBOARD',
  endSession: 'END_SESSION',
  userNotLoggedIn: 'USER_NOT_LOGGED_IN',
  guestReview: 'GUEST_REVIEW',
  completeTask: 'COMPLETE_TASK',
  storageConfigurationModal: 'STORAGE_CONFIGURATION_MODAL',
  upgradeSubscriptionModal: 'UPGRADE_SUBSCRIPTION_MODAL',
  deleteTeam: 'DELETE_TEAM',
  deleteTeamUser: 'DELETE_TEAM_USER',
  restrictedFilesUpload: 'RESTRICTED_FILES_UPLOAD',
  uploadToAmazonS3: 'UPLOAD_TO_AMAZON_S3',
  shareToContido: 'SHARE_TO_AMAZON_S3',
  createFolder: 'PROJECT_CREATE_FOLDER',
  copyToProject: 'COPY_TO_PROJECT',
  copyToFolder: 'COPY_TO_FOLDER',
  moveToProject: 'MOVE_TO_PROJECT',
  moveToFolder: 'MOVE_TO_FOLDER',
  downloadWithPasscode: 'DOWNLOAD_WITH_PASSCODE',
  shareToSprinklr: 'SHARE_TO_SPRINKLR',
  systemReport: 'SYSTEM_REPORT',
  selectCameraModel: 'SELECT_CAMERA_MODEL',
};

const SHARE_TYPES = {
  approve: 'task_approval',
  review: 'task_review',
};

const ASSET_STATUS = {
  noStatus: {
    label: 'No Status',
    value: 0,
    color: '#aaa',
  },
  inProgress: {
    label: 'In Progress',
    value: 1,
    color: '#FFD700',
  },
  needsReview: {
    label: 'Needs Review',
    value: 2,
    color: '#FF8D2C',
  },
  approved: {
    label: 'Approved',
    value: 3,
    color: '#18B96E',
  },
};

const UPLOAD_STATUS = {
  finished: 1,
  inprogress: 3,
};

const TRANS_STATUS = {
  initiated: 0,
  finished: 1,
  failed: 2,
  inprogress: 3,
  inqueue: 4,
  thumbnailGenerated: 5,
  previewNotAvailable: 7,
};

const UPDATED_ASSET_STATUS = [
  ASSET_STATUS?.noStatus,
  ASSET_STATUS?.inProgress,
  ASSET_STATUS?.needsReview,
  ASSET_STATUS?.approved,
];

export const VALIDATION_MESSAGES = {
  onlyNumbers: 'Enter numbers only',
  noSpecialCharacters: 'Remove special characters',
  noSpaces: 'Remove all the blank spaces',
};

export const APP_TYPES = {
  app: 'app',
  web: 'web-app',
};

export const SIDEBAR_QUERY_TYPES = {
  joinLiveCollaboration: 'join-live-collaboration',
  projectChat: 'project-chat',
  cameraToEdit: {
    newSession: 'new-session',
  },
};

export const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export {
  MODAL_TYPES,
  SHARE_TYPES,
  ASSET_STATUS,
  UPLOAD_STATUS,
  TRANS_STATUS,
  UPDATED_ASSET_STATUS,
};
