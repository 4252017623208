import {
  LOAD_ALL_BUCKET_DETAILS,
  LOAD_BUCKET_FILES,
  RESET_IMPORT_ASSETS,
  LOAD_PROJECT_DETAILS,
  LOAD_PROJECT_BREADCRUMBS,
} from './types';

const INITIAL_STATE = {
  destination: null,
  bucketDetails: null,
  folderFiles: null,
  selectedProject: null,
  projectBreadcrumbs: null,
};

export default function importAssetsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_ALL_BUCKET_DETAILS: {
      return {
        ...state,
        bucketDetails: { ...action?.payload },
      };
    }

    case LOAD_BUCKET_FILES: {
      const { results, type } = action.payload;

      if (type === 'pagination') {
        const updated = { ...state?.folderFiles };
        updated.page_no = results?.page_no;
        updated.files = [...state?.folderFiles?.files, ...results?.files];
        updated.next_page_token = results?.next_page_token || null;
        return {
          ...state,
          folderFiles: { ...updated },
        };
      }

      return {
        ...state,
        folderFiles: results || null,
      };
    }

    case RESET_IMPORT_ASSETS: {
      return INITIAL_STATE;
    }

    case LOAD_PROJECT_DETAILS: {
      const { results } = action.payload;
      const { title, team_db_id, project_id } = results;
      return {
        ...state,
        selectedProject: { title, team_db_id, project_id },
      };
    }

    case LOAD_PROJECT_BREADCRUMBS: {
      const { results } = action.payload;
      const { breadcrumbs } = results;
      return {
        ...state,
        projectBreadcrumbs: { breadcrumbs },
      };
    }

    default:
      return state;
  }
}
