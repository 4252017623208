const LOAD_DETAILS = 'LOAD_DETAILS';
const LOAD_ASSETS = 'LOAD_ASSETS';
const UPDATE_ASSETS = 'UPDATE_ASSETS';
const LOAD_MEMBERS = 'LOAD_MEMBERS';
const LOAD_ALL_TASK = 'LOAD_ALL_TASK';
const LOAD_SELECTED_TASK = 'LOAD_SELECTED_TASK';
const UPDATE_UPLOAD_FILE = 'UPDATE_UPLOAD_FILE';
const LOAD_CLOUD_ACCESS_DETAILS = 'LOAD_CLOUD_ACCESS_DETAILS';
const RESET_STORYBOARD_STATE = 'RESET_STORYBOARD_STATE';
const TOGGLE_ASSIGNED_TASK_VIEW = 'TOGGLE_ASSIGNED_TASK_VIEW';
const LOAD_MESSAGES = 'LOAD_MESSAGES';
const LOAD_IMPORT_ASSET_STATUS = 'LOAD_IMPORT_ASSET_STATUS';
const LIST_VIEW = 'LIST_VIEW';
const SELECTED_ITEMS = 'selectedItems';
const SELECTED_FILTERS = 'selectedFilters';

export default {
  LOAD_DETAILS,
  LOAD_ASSETS,
  LOAD_MEMBERS,
  UPDATE_ASSETS,
  LOAD_ALL_TASK,
  LOAD_SELECTED_TASK,
  UPDATE_UPLOAD_FILE,
  LOAD_CLOUD_ACCESS_DETAILS,
  RESET_STORYBOARD_STATE,
  LOAD_MESSAGES,
  TOGGLE_ASSIGNED_TASK_VIEW,
  LIST_VIEW,
  LOAD_IMPORT_ASSET_STATUS,
  SELECTED_ITEMS,
  SELECTED_FILTERS,
};
