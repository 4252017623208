import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { ImportExport } from '@material-ui/icons';

import { getImportAssetStatusService } from '../duck/operations';
import { setImportAssetStatus } from '../duck/actions';
import CustomMenu from '../../components/menu';
import InfiniteScroll from '../../components/infiniteScroll';
import { colors } from '../../styles/variables';

import { Text } from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';
import { ActionButtonStyles } from './styles';

const TransfersDivStyles = styled.div`
  p {
    padding: 0;
  }
  ul {
    max-height: 25rem;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    padding: 1.3rem 1.5rem;
    border-bottom: 0.1rem solid ${whiteAlpha(0.2)};
  }

  li:last-child {
    border-bottom: none;
  }

  .asset-name {
    border-bottom: 0.1rem solid ${whiteAlpha(0.2)};
    padding: 1.3rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > button {
      background: transparent;
      border: none;
      cursor: pointer;
      color: ${colors.blue.shadeTwo};
      &:hover {
        color: ${colors.blue.primary};
      }
    }
  }
`;

const TransferDataStatus = ({ match: { params }, history }) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const dispatch = useDispatch();
  const { transfers } = useSelector(state => state.storyboard);
  const selectedTeam = useSelector(store => store.common?.selectedTeam);
  const commonRequest = {
    team_db_id: selectedTeam?.team_db_id,
    project_db_id: params?.project_db_id,
    entries_per_page: 25,
  };

  useEffect(() => {
    const getImportStatus = async () => {
      const res = await getImportAssetStatusService({
        ...commonRequest,
        page_no: 1,
      });

      if (res?.data?.response) {
        if (res?.data?.data?.show_notification) {
          dispatch(setImportAssetStatus({ data: res?.data?.data }));
        }
      }
    };

    getImportStatus();
  }, []);

  const onScrollLoad = async () => {
    if (transfers?.transfers?.length < transfers?.total_entries) {
      setIsLoadingMore(true);
      const res = await getImportAssetStatusService({
        ...commonRequest,
        page_no: transfers?.page_no + 1,
      });

      if (res?.data?.response) {
        setIsLoadingMore(false);
        dispatch(
          setImportAssetStatus({ type: 'pagination', data: res?.data?.data })
        );
      } else {
        setIsLoadingMore(false);
      }
    }
  };

  if (transfers?.transfers?.length > 0) {
    return (
      <CustomMenu
        maxHeight='30rem'
        icon={
          <ActionButtonStyles role='presentation'>
            <ImportExport />
            <Text>Transfers</Text>
          </ActionButtonStyles>
        }
        customMenu={
          <>
            <TransfersDivStyles>
              <div className='asset-name' style={{ minWidth: '25rem' }}>
                <Text style={{ maxWidth: '30rem' }}>Asset Transfers</Text>
                <button
                  type='button'
                  onClick={() => {
                    history.push(`/transfers/${params?.project_db_id}/contido`);
                  }}>
                  View All
                </button>
              </div>
              <ul>
                <InfiniteScroll
                  isLoading={isLoadingMore}
                  loadAssets={onScrollLoad}>
                  {transfers?.transfers?.map((el, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>
                      <Text
                        style={{
                          maxWidth: '30rem',
                          wordWrap: 'break-word',
                          overflowWrap: 'break-word',
                          whiteSpace: 'normal',
                        }}
                        paddingBottom='0.5rem'>
                        {el?.title}
                      </Text>
                      <Text xs color={whiteAlpha(0.8)}>
                        <span>{el?.status}</span>
                        <span> | </span>
                        {el?.share_from_ps ? 'Shared' : 'Imported'}
                        <span> | </span> <span>{el?.transfer_type}</span>
                      </Text>
                    </li>
                  ))}
                </InfiniteScroll>
                {isLoadingMore && (
                  <Text
                    color={whiteAlpha(0.8)}
                    style={{ textAlign: 'center' }}
                    sm>
                    Loading more...
                  </Text>
                )}
              </ul>
            </TransfersDivStyles>
          </>
        }
      />
    );
  }

  return null;
};

TransferDataStatus.propTypes = {
  match: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
};

export default withRouter(TransferDataStatus);
