import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Add } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { Normal } from '../../../../components/Button';

import { sprinklrIntegrationService } from '../../../duck/operations';

import sidebarTypes from '../../../../components/Sidebar/constants';
import { SPRINKLR_STEPS } from '../../../constants/integration';

import { Body, Footer } from '../../../../styles/sidebar';
import { Text, NewAddButtonStyles } from '../../../../styles/common';
import { ListContainerStyles, ListItemExtendedStyles } from '../index';

const List = ({ goTo, setIntegration, toggleSidebar }) => {
  const selectedTeam = useSelector(store => store?.common.selectedTeam);
  const [list, setList] = useState(null);

  useEffect(() => {
    const getList = async () => {
      try {
        const { data } = await sprinklrIntegrationService({
          team_db_id: selectedTeam?.team_db_id,
          team_id: selectedTeam?.team_db_id,
        });

        if (data?.response) {
          setList(data?.data?.integrations);
        } else throw new Error(data?.errormessage);
      } catch (err) {
        console.log('🚀 ~ getList ~ err:', err?.message);
      }
    };
    getList();
  }, []);

  return (
    <>
      <Body>
        <ListContainerStyles>
          <li>
            <NewAddButtonStyles
              className='flex items-center'
              onClick={() => goTo(SPRINKLR_STEPS.newEdit)}>
              <span className='icon'>
                <Add />
              </span>
              <h4>New Integration</h4>
            </NewAddButtonStyles>
          </li>
          {list?.length > 0 && (
            <li className='flex items-center justify-between'>
              <Text padding='1rem 0 0 0'>Existing Integrations</Text>
            </li>
          )}
          {list?.map(el => (
            <li key={el?.integration_db_id}>
              <ListItemExtendedStyles
                style={{ gridTemplateColumns: 'repeat(2,minmax(0,1fr))' }}
                type='button'
                onClick={() => {
                  setIntegration(el?.integration_db_id);
                  goTo(SPRINKLR_STEPS.details);
                }}>
                <>
                  {el?.campaign_name && (
                    <div aria-label='Environment'>
                      <p>Environment</p>
                      <h4>{el?.environment}</h4>
                    </div>
                  )}
                  {el?.business_unit && (
                    <div aria-label='Business Unit'>
                      <p>Business Unit</p>
                      <h4>{el?.business_unit}</h4>
                    </div>
                  )}
                  {el?.campaign_id && (
                    <div aria-label='Campaign ID'>
                      <p>Campaign ID</p>
                      <h4>{el?.campaign_id}</h4>
                    </div>
                  )}
                  {el?.campaign_name && (
                    <div aria-label='Campaign Name'>
                      <p>Campaign Name</p>
                      <h4>{el?.campaign_name}</h4>
                    </div>
                  )}
                  {el?.agency_for_media_assets && (
                    <div aria-label='Agency - Media Assets'>
                      <p>Agency - Media Assets</p>
                      <h4>{el?.agency_for_media_assets}</h4>
                    </div>
                  )}
                </>
              </ListItemExtendedStyles>
            </li>
          ))}
        </ListContainerStyles>
      </Body>
      <Footer>
        <Normal
          type='submit'
          onClick={() => {
            toggleSidebar({
              type: sidebarTypes.integration,
              show: true,
              title: 'Applications',
            });
          }}
          color='secondary'>
          Back
        </Normal>
      </Footer>
    </>
  );
};

List.propTypes = {
  goTo: PropTypes.func,
  setIntegration: PropTypes.func,
  toggleSidebar: PropTypes.func,
};

export default List;
