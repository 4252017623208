import { format } from 'date-fns';

import serviceCall from '../utils/api';

import { FILE_EXTENSTIONS_NOT_ALLOWED } from './storyboard/constants';

const formatLabelValue = (arr, label, value, username) => {
  const formated =
    arr &&
    arr.map(item => {
      return {
        label: item[label],
        value: item[value],
        ...(username && { username: item[username] }),
      };
    });
  return formated;
};

const getQueryParams = location =>
  location &&
  Object.keys(location).length &&
  new URLSearchParams(location?.search);

const serviceCallBasedOnMethod = (api, request, method) => {
  /*
   * will return promise after executing serviceCall
   * use this when you have same API but different method like post, put etc
   */

  let promise = null;

  if (method === 'post') {
    promise = serviceCall.post(api, request);
  } else if (method === 'put') {
    promise = serviceCall.put(api, request);
  } else if (method === 'delete') {
    promise = serviceCall.delete(api, request);
  } else if (method === 'get') {
    promise = serviceCall.get(api, request);
  }

  return promise;
};

const amountWithCommas = (amount = 0) => amount.toLocaleString('en-IN');

const getDaysFromCurrentDate = date => {
  // date has to be in string format eg dd/mm/yyyy
  if (typeof date === 'string') {
    const formattedDate = date.split('/');
    return parseInt(
      (new Date(`${formattedDate[1]}/${formattedDate[0]}/${formattedDate[2]}`) -
        new Date()) /
        (1000 * 60 * 60 * 24),
      10
    );
  }

  return null;
};

const debounce = cb => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      cb(...args);
    }, 1000);
  };
};

export const restrictFileExtensionsForUploads = name =>
  name
    .toLowerCase()
    .split('.')
    .slice(1)
    .some(el => FILE_EXTENSTIONS_NOT_ALLOWED.includes(el));

export const launchApplication = (type, cloudAccessDetails, queryParams) => {
  if (type === 'web') {
    const base = `${process.env.BASE_NICE_DCV_PATH}?token=${cloudAccessDetails?.launch_token}`;
    const url = queryParams ? `${base}${queryParams}` : base;
    window.open(url, '_blank').focus();
  } else {
    const href = `postudio://${cloudAccessDetails.launch_token}`;
    window.location.href = href;
  }
};

export const getMinutes = total => Math.floor(total / 60);
export const getSeconds = total => total % 60;
export const formatMinutesSeconds = num => num.toString().padStart(2, '0');

export const getOtp = values => {
  let otp = '';
  for (let i = 0; i <= values.length - 1; i += 1) {
    otp += values[i].value;
  }
  return otp;
};

export const getMenuItems = menu =>
  menu?.map(el => ({ name: el?.label, field: el?.value }));

export const onInputChangeCharactersLimit = (e, cb) => {
  if (e.target.value.length < 102) {
    cb(e);
  } else {
    e.preventDefault();
  }
};

export const isBrowser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined';

export const toggleTwak = () => {
  if (window?.Tawk_API?.toggle) {
    window.Tawk_API.toggle();
  }
};

export const formatDateTime = epoch => {
  if (!epoch) return null;
  const date = format(epoch, 'dd MMM yyyy');
  const time = format(epoch, 'p');
  return { date, time };
};

export const getTimeZone = () => {
  return {
    name: new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
      ?.substring(4),
    offset: new Date().getTimezoneOffset(),
  };
};

export {
  formatLabelValue,
  serviceCallBasedOnMethod,
  getQueryParams,
  amountWithCommas,
  getDaysFromCurrentDate,
  debounce,
};
