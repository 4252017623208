import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Add } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { Normal } from '../../../../components/Button';
import { contidoIntegrationService } from '../../../duck/operations';

// constants
import { CONTIDO_STEPS } from '../../../constants/integration';
import sidebarTypes from '../../../../components/Sidebar/constants';

// styles
import { Body, Footer } from '../../../../styles/sidebar';
import { Text, NewAddButtonStyles } from '../../../../styles/common';
import { ListContainerStyles, ListItemExtendedStyles } from '../index';

const IntegratedRegions = ({ goTo, selectBucketId, toggleSidebar }) => {
  const selectedTeam = useSelector(store => store?.common.selectedTeam);
  const [list, setList] = useState([]);

  useEffect(() => {
    contidoIntegrationService({
      team_db_id: selectedTeam?.team_db_id,
      team_id: selectedTeam?.team_db_id,
    })
      .then(res => {
        if (res?.data?.response) {
          setList(res?.data?.data?.integrations);
        } else throw new Error();
      })
      .catch();
  }, []);

  return (
    <>
      <Body>
        <ListContainerStyles>
          <li>
            <NewAddButtonStyles
              className='flex items-center'
              onClick={() => goTo(CONTIDO_STEPS.newEdit)}>
              <span className='icon'>
                <Add />
              </span>
              <h4>New Integration</h4>
            </NewAddButtonStyles>
          </li>
          {list?.length > 0 && (
            <li className='flex items-center justify-between'>
              <Text padding='1rem 0 0 0'>Existing Integrations</Text>
            </li>
          )}
          {list?.map(el => (
            <li key={el?.integration_db_id}>
              <ListItemExtendedStyles
                onClick={() => {
                  selectBucketId(el?.integration_db_id);
                  goTo(CONTIDO_STEPS.bucketDetails);
                }}
                type='button'>
                <>
                  {el?.input_bucket && (
                    <div aria-label='input-bucket-name'>
                      <p>Input Bucket Name</p>
                      <h4>{el?.input_bucket}</h4>
                    </div>
                  )}
                  {el?.output_bucket && (
                    <div aria-label='output-bucket-name'>
                      <p>Output Bucket Name</p>
                      <h4>{el?.output_bucket}</h4>
                    </div>
                  )}
                </>
              </ListItemExtendedStyles>
            </li>
          ))}
        </ListContainerStyles>
      </Body>
      <Footer>
        <Normal
          type='button'
          onClick={() => {
            toggleSidebar({
              type: sidebarTypes.integration,
              show: true,
              title: 'Applications',
            });
          }}
          color='secondary'>
          Back
        </Normal>
      </Footer>
    </>
  );
};

IntegratedRegions.propTypes = {
  selectBucketId: PropTypes.func,
  goTo: PropTypes.func,
  toggleSidebar: PropTypes.func,
};

export default IntegratedRegions;
